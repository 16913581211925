.experience{
    margin: auto;

    .experience-grid {
        display: grid;
        grid-template-columns: 0.5fr 1fr;
        align-items: center;
        margin: 25px 0;

        img {
            display: block;
            margin: 20px auto;
            width: 200px;
        }
    }
}

.education {
    width: 50%;
}

.work {
    width: 70%;

    .experience-grid {
        gap: 25px;
        grid-template-columns: 0.5fr 3fr;
    }
}

.freelance .experience-grid {
    margin: auto 100px auto 60px;
    grid-template-columns: 0.8fr 1fr;
    align-items: normal;

    .client-logos {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .clients {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    
        .client {
            margin: 12px 0px 12px 0px;
    
            .name {
                font-weight: 700;
            }
        }
    }

}

/* For mobile phones: */
@media only screen and (max-width: 768px) {

    .experience{
        width: 100%;
    }

        .experience .experience-grid {
            gap: 25px;
        } 

    .work .experience-grid, .education .experience-grid, .freelance .experience-grid {
        display: block;
    }

    .freelance .experience-grid {
        margin: auto 20px;

        .clients {
            font-size: 12px;
        }
    } 

}